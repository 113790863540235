import React, { useState, useEffect } from "react";
import { collection, addDoc, updateDoc, doc, deleteDoc, getDocs } from "firebase/firestore"; // Firestore functions
import { useFirebase } from "../firebase"; // Adjust this import based on your project structure

const ClientAdmin = () => {
  const { db, uploadImageToStorage } = useFirebase(); // Firestore and Firebase Storage upload function
  const [formType, setFormType] = useState(""); // Track form type (add or edit)
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [type, setType] = useState("");
  const [caratRate, setCaratRate] = useState("");
  const [purity, setPurity] = useState("");
  const [goldWeight, setGoldWeight] = useState("");
  const [category, setCategory] = useState("");
  const [editingProductId, setEditingProductId] = useState(null); // Product ID for editing
  const [products, setProducts] = useState([]); // List of products
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");

  // State for image uploads
  const [mainImage, setMainImage] = useState(null);
  const [thumbnail1, setThumbnail1] = useState(null);
  const [thumbnail2, setThumbnail2] = useState(null);

  // Fetch existing products from Firestore
  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsArray);
    };
    fetchProducts();
  }, [db]);

  // Handle form submit for adding or updating products
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Upload images to Firebase Storage
      const mainImageUrl = await uploadImageToStorage(mainImage);
      const thumbnail1Url = await uploadImageToStorage(thumbnail1);
      const thumbnail2Url = await uploadImageToStorage(thumbnail2);

      const productData = {
        title,
        price,
        type,
        caratRate,
        purity,
        goldWeight,
        category,
        mainImageUrl,
        thumbnail1: thumbnail1Url,
        thumbnail2: thumbnail2Url,
        subCategory
      };

      console.log("Product Data =", JSON.stringify(productData, null, 2));

      if (formType === "add") {
        // Add product
        await addDoc(collection(db, "products"), productData);
        console.log("Product added successfully!");
      } else if (formType === "edit" && editingProductId) {
        // Edit product
        const productRef = doc(db, "products", editingProductId);
        await updateDoc(productRef, productData);
        console.log("Product updated successfully!");
      }

      // Clear form fields after submission
      clearFormFields();
    } catch (error) {
      console.error("Error handling product:", error);
    }
  };

  // Handle switching between add and edit/delete forms
  const handleShowForm = (type) => {
    setFormType(type);
    setEditingProductId(null); // Reset the editing product ID if switching forms
    clearFormFields(); // Clear the form fields when switching between forms
  };

  // Clear form fields function
  const clearFormFields = () => {
    setTitle("");
    setPrice("");
    setType("");
    setCaratRate("");
    setPurity("");
    setGoldWeight("");
    setCategory("");
    setMainImage(null);
    setThumbnail1(null);
    setThumbnail2(null);
    setSubCategory("");
  };

  // Start editing a product
  const startEditing = (product) => {
    setFormType("edit");
    setEditingProductId(product.id);
    setTitle(product.title);
    setPrice(product.price);
    setType(product.type);
    setCaratRate(product.caratRate);
    setPurity(product.purity);
    setGoldWeight(product.goldWeight);
    setCategory(product.category);
    setSubCategory(product.subCategory);
    // Assuming the product has mainImageUrl and thumbnailUrls
    setMainImage(product.mainImageUrl);
    setThumbnail1(product.thumbnail1);
    setThumbnail2(product.thumbnail2);
  };

  // Handle delete
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "products", id));
      setProducts(products.filter((product) => product.id !== id));
      console.log("Product deleted successfully!");
    } catch (error) {
      console.error("Error deleting product: ", error);
    }
  };

  const filteredProducts = selectedCategory
    ? products.filter((product) => product.category === selectedCategory)
    : products;

  return (
    <div className="p-4">
      <h1 className="text-center mb-4">Admin Panel</h1>

      {/* Buttons always visible at the top */}
      <div className="text-center mb-4">
        <button
          className="btn btn-primary me-2"
          onClick={() => handleShowForm("add")}
        >
          Add Product
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => handleShowForm("edit")}
        >
          Edit / Delete
        </button>
      </div>

      {/* Add or Edit form */}
      {formType && (
        <form onSubmit={handleSubmit} className="p-4 border rounded bg-light shadow-sm">
          <div className="mb-3">
            <label className="form-label">Category:</label>
            <select
              className="form-select"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Select Category</option>
              <option value="Rings">Rings</option>
              <option value="Earrings">Earrings</option>
              <option value="Pendants">Pendants</option>
              <option value="Necklace">Necklace</option>
              <option value="Bracelets">Bracelets</option>
              <option value="Bracelets">Kids</option>
              <option value="Bracelets">Mens</option>
              <option value="Bracelets">Women</option>
            </select>
          </div>

          {/* Sub Category */}
          <div className="mb-3">
            <label className="form-label">Select Sub Category:</label>
            <select
              className="form-select"
              value={subCategory}
              onChange={(e) => setSubCategory(e.target.value)}
              required
            >
              <option value="">Select Category</option>
              <option value="First Coursel">First Coursel</option>
              <option value="Second Coursel">Second Coursel</option>
              <option value="Third Coursel">Third Coursel</option>
              <option value="None">None</option>
            </select>
          </div>

          <div className="mb-3">
            <label className="form-label">Title:</label>
            <input
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Price:</label>
            <input
              type="text"
              className="form-control"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Type:</label>
            <input
              type="text"
              className="form-control"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Carat Rate:</label>
            <input
              type="number"
              className="form-control"
              value={caratRate}
              onChange={(e) => setCaratRate(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Purity:</label>
            <input
              type="text"
              className="form-control"
              value={purity}
              onChange={(e) => setPurity(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Gold Weight:</label>
            <input
              type="number"
              className="form-control"
              value={goldWeight}
              onChange={(e) => setGoldWeight(e.target.value)}
              required
            />
          </div>

          {/* Image Upload Section */}
          <div className="mb-3">
            <label className="form-label">Main Image:</label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={(e) => setMainImage(e.target.files[0])}
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Thumbnail Image 1:</label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={(e) => setThumbnail1(e.target.files[0])}
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Thumbnail Image 2:</label>
            <input
              type="file"
              className="form-control"
              accept="image/*"
              onChange={(e) => setThumbnail2(e.target.files[0])}
              required
            />
          </div>

          <button type="submit" className="btn btn-primary">
            {formType === "add" ? "Add Product" : "Update Product"}
          </button>
        </form>
      )}

      {/* Display list of existing products for editing or deleting */}
      {formType === "edit" && (
        <>
          <div className="mt-4">
            <label className="form-label">Filter by Category:</label>
            <select
              className="form-select"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">All Categories</option>
              <option value="Rings">Rings</option>
              <option value="Earrings">Earrings</option>
              <option value="Pendants">Pendants</option>
              <option value="Necklace">Necklace</option>
              <option value="Bracelets">Bracelets</option>
            </select>
          </div>
          <ul className="list-group mt-4">
            {filteredProducts.map((product) => (
              <li key={product.id} className="list-group-item d-flex justify-content-between align-items-center">
                <span>{product.title} - {product.price}</span>
                <div>
                  <button className="btn btn-sm btn-warning me-2" onClick={() => startEditing(product)}>
                    Edit
                  </button>
                  <button className="btn btn-sm btn-danger" onClick={() => handleDelete(product.id)}>
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default ClientAdmin;



