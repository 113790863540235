import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ProductDetail.module.css"; // Ensure this file exists

const ProductDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const product = location.state?.product;

  if (!product) {
    return <div>No product found.</div>;
  }

  return (
    <div className={styles.container}>
      <div className={styles.singleProduct}>
        <div className={styles.row}>
          <div className={styles.col6}>
            <div className={styles.productImage}>
              <div className={styles.productImageMain}>
                <img
                  src={product.mainImageUrl}
                  alt={product.title}
                  id="product-main-image"
                />
              </div>
              <div className={styles.productImageSlider}>
                <img
                  src={product.thumbnail1}
                  alt="Thumbnail 1"
                  className={styles.imageList}
                />
                <img
                  src={product.thumbnail2}
                  alt="Thumbnail 2"
                  className={styles.imageList}
                />
              </div>
            </div>
          </div>

          <div className={styles.col6}>
            <div className={styles.breadcrumb} style={{ textAlign: 'left' }}>
              <span><a href="/">Home</a></span>
              <span onClick={() => navigate(-1)}>
              <button className={styles.breadcrumbButton}>Product</button>
              </span>
              <span style={{ textAlign: 'left' }}>{product.title}</span>
            </div>

            {/* <div className={styles.productSKU}>
              <p>SKU: {product.type}</p>
            </div> */}

            <div className={styles.product}>
              <div className={styles.productTitle}>
                <h2 style={{ textAlign: 'left' }}>{product.title}</h2>
              </div>

              <div className={styles.productPrice}>
                <span className={styles.offerPrice}>₹{product.caratRate}</span>
              </div>

              <div className={styles.priceinfo} style={{ textAlign: 'left' }}>
                <span>
                  Price exclusive of taxes. See the full price breakup
                </span>
              </div>

              <div className={styles.productColor} style={{ textAlign: 'left' }}>
                <h4>Colors
                  <span className={styles.colorLayout}>
                    {/* <input type="radio" name="color" value="rose-gold" className={styles.colorInput} id="rose-gold" /> */}
                    <label htmlFor="rose-gold" className={styles.roseGold}></label>
                    {/* <input type="radio" name="color" value="white-gold" className={styles.colorInput} id="white-gold" /> */}
                    <label htmlFor="white-gold" className={styles.whiteGold}></label>
                    {/* <input type="radio" name="color" value="yellow-gold" className={styles.colorInput} id="yellow-gold" /> */}
                    <label htmlFor="yellow-gold" className={styles.yellowGold}></label>
                  </span></h4>
              </div>

              <div className={styles.productPurity} style={{ textAlign: 'left' }}>
                <p>Metal
                  <span className={styles.sizeLayout}>
                    {/* <input type="radio" name="size" value="S" id="size1" className={styles.sizeInput} /> */}
                    <label htmlFor="size1" className={styles.size}>14Kt <br /> GOLD</label>
                    <span style={{ paddingLeft: '25px' }}> OR </span>
                    {/* <input type="radio" name="size" value="M" id="size2" className={styles.sizeInput} /> */}
                    <label htmlFor="size2" className={styles.size}>18Kt <br /> GOLD</label>
                  </span></p>
              </div>



              <span className={styles.divider}></span>

              <div className={styles.productBtnGroup}>
                <button className={styles.buyNow}>Buy Now</button>
                <button className={styles.addCart}>Add to Cart</button>
                <button className={styles.heart}>Add to Wishlist</button>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.information}>
          <h4>Additional Information</h4>
          <div className={styles.row}>
            <div className={styles.col6}>
              <div className={styles.productDetails}>
                <h5>Product Details</h5>
                <div className={styles.productInfoWrapper}>
                  <span className={styles.productInfoBox}>
                    <p><strong>Gold Weight:</strong> {product.goldWeight}</p>
                  </span>
                  <span className={styles.productInfoBox}>
                    <p><strong>Purity:</strong> {product.purity} <br /></p>
                  </span>

                </div>
              </div>
              <div className={styles.priceBreakup}>
                <h5>Price Breakup</h5>
                <table>
                  <tbody>
                    <tr>
                      <td class={styles.itemName}>Gold</td>
                      <td><span class={styles.itemCost}>Rs.</span><span>6338/-</span></td>
                      </tr>
                      <tr>
                        <td class={styles.itemName}>Diamond</td>
                        <td><span class={styles.itemCost}>Rs.</span><span>4928/-</span></td>
                        </tr>
                        <tr>
                          <td class={styles.itemName}>Making Charge</td>
                          <td><span class={styles.itemCost}>Rs.</span><span>1800/-</span></td>
                          </tr>
                          <tr><td class={styles.itemName}>GST</td>
                          <td><span class={styles.itemCost}>Rs.</span><span> 391/- </span></td>
                          </tr>
                          <tr><td class={styles.itemName}>Total</td>
                          <td><span class={styles.itemCost}>Rs.</span><span>13458/- </span></td>
                          </tr>
                          <tr><td class={styles.itemInfo}><b>This is an estimated price, actual price may differ as per actual weights.</b></td>
                          </tr>
                          </tbody>
                </table>
                <span className={styles.itemName}></span><span></span>

              </div>
            </div>
            <div className={styles.col6}>
              <div className={styles.ourPromise}>
                <h5>Our Promise</h5>
                <p>80% Buyback</p>
                <p>100% Exchange</p>
                <p>Easy 15 Days Return</p>
                <p>Free Shipping & Insurance</p>
                <p>Hallmarked Gold</p>
                <p>Certified Jewellery</p>
              </div>
              <div className={styles.certificateOfAuthenticity}>
                <h4 className={styles.certificateHeading}>Certificate Of Authenticity</h4>
                <p className={styles.certificateInfo}>Every piece of jewellery that we make is certified for authenticity by third-party international laboratories like <strong>SGL</strong> and <strong>IGI</strong>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
