import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPrint } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    // <div className="my-5" style={{ marginBottom: '0px' }}>
    //   <footer className="text-center text-lg-start text-white">
    //     <div className="container p-4 pb-0">
    //       <section>
    //         <div className="row footer-row">

    //           {/* LOGO */}
    //           <div className="col-md-5 col-lg-4 mx-auto mt-4 logo-col">
    //             <img className="logo-footer" src="./Logo-main.jpg" alt="logo" />
    //           </div>

    //           {/* DESCRIPTION */}
    //           <div className="col-md-4 col-lg-3 mx-auto mt-3 description-col">
    //             <p className="logo-text">
    //               Wore a stunning necklace adorned with shimmering pearls and dazzling diamonds, gracefully accentuating her neckline.
    //             </p>
    //           </div>

    //           {/* PRODUCTS */}
    //           <div className="col-md-2 col-lg-2 mx-auto mt-3 products-col">
    //             <h4 className="text-uppercase mb-4 font-weight-bold">Products</h4>
    //             <p>
    //             <a className="text-white text-18" href="www.google.com">Rings</a>
    //           </p>
    //           <p>
    //             <a className="text-white text-18" href="www.google.com">Necklace</a>
    //           </p>
    //           <p>
    //             <a className="text-white text-18" href="www.google.com">Pendants</a>
    //           </p>
    //           <p>
    //             <a className="text-white text-18" href="www.google.com">Bracelates</a>
    //           </p>
    //           </div>

    //           {/* CONTACT */}
    //           <div className="col-md-3 col-lg-3 mx-auto mt-3 contact-col">
    //             <h4 className="text-uppercase mb-4 font-weight-bold">Contact</h4>
    //             <p><FontAwesomeIcon icon={faHome} className="mr-3" /> Delhi, India</p>
    //             <p><FontAwesomeIcon icon={faEnvelope} className="mr-3" /> info@example.com</p>
    //             <p><FontAwesomeIcon icon={faPhone} className="mr-3" /> +91 123 456 7890</p>
    //             <Link to="/signin" style={{ textDecoration: "none" }}>
    //   <p style={{ cursor: "pointer" }}>
    //     <FontAwesomeIcon icon={faPrint} className="mr-3" />
    //     Client Admin
    //   </p>
    // </Link>
    //           </div>

    //         </div> {/* row ends */}
    //       </section>
    //     </div>
    //   </footer>
    // </div>

<>
<div className="container-fluid my-5" style={{padding: '0px'}}>
      <footer className="text-center text-lg-start text-white" style={{padding:'0px 0px 0px 0px !important'}}>
        <div className="container p-4">
          <div className="row my-4">
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <div
                className="rounded-circle bg-white shadow-1-strong d-flex align-items-center justify-content-center mb-4 mx-auto"
                style={{ width: "150px", height: "150px" }}
              >
                <img
                  src="./Logo-main.jpg" 
                  height="170"
                  alt="logo"
                  loading="lazy"
                />
              </div>
              <p className="text-center">
                Homeless animal shelter The budgetary unit of the Capital City of Warsaw
              </p>
              <ul className="list-unstyled d-flex flex-row justify-content-center">
                <li>
                  <a className="text-white px-2" href="#!">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                </li>
                <li>
                  <a className="text-white px-2" href="#!">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a className="text-white ps-2" href="#!">
                    <i className="fab fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase mb-4">Animals</h5>
              <ul className="list-unstyled">
                {["When your pet is missing", "Recently found", "How to adopt?", "Pets for adoption", "Material gifts", "Help with walks", "Volunteer activities"].map((item, index) => (
                  <li className="mb-2" key={index}>
                    <a href="#!" className="text-white">
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase mb-4">General Info</h5>
              <ul className="list-unstyled">
                {["General information", "About the shelter", "Statistic data", "Job", "Tenders", "Contact"].map((item, index) => (
                  <li className="mb-2" key={index}>
                    <a href="#!" className="text-white">
                      {item}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5 className="text-uppercase mb-4">Contact</h5>
              <ul className="list-unstyled">
                <li>
                  <p>
                    <i className="fas fa-map-marker-alt pe-2"></i>Warsaw, 57 Street, Poland
                  </p>
                </li>
                <li>
                  <p>
                    <i className="fas fa-phone pe-2"></i>+ 01 234 567 89
                  </p>
                </li>
                <li>
                <Link to="/signin" style={{ textDecoration: "none" }}>
      <p style={{ cursor: "pointer" }}>
       <FontAwesomeIcon icon={faPrint} className="mr-3" />
       Client Admin
       </p>
    </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
        © 2020 Copyright : 
          <a className="text-white" href="https://mdbootstrap.com/">
             MAYUKH
          </a>
        </div>
      </footer>
    </div>

    </>

  );
}

export default Footer;
