import React, { useEffect, useRef } from "react";
import "./TopCategories.css";
import p1Image from "./images/p1.png";
import p2Image from "./images/p2.png";
import p4Image from "./images/p4.png";
import p5Image from "./images/p5.png";
import { Link } from "react-router-dom";

const Card = ({ title, image }) => {
  return (
    <div className="card-container">
      <div className="card">
        <img src={image} alt={title} className="card-image" />
        <Link  className="card-button">
          {title}
        </Link>
      </div>
    </div>
  );
};

const cardData = [
  { title: "Gifts Under 25k", image: "pc.jpg", link: "/gifts-under-25k" },
  { title: "Gifts Under 50k", image: "festive2.jpg", link: "/gifts-under-50k" },
  { title: "Festive Collection", image: "gu25k.jpg", link: "/festive-collection" },
  { title: "Personalised Collection", image: "gu50k2.jpg", link: "/personalised-collection" },
];


const products = [
    { image: p1Image, name: "Rings",link:"Rings" },
    { image: p2Image, name: "Necklace",link:"Necklace" },
    { image: p1Image, name: "Pendants",link:"Pendants" },
    { image: p4Image, name: "Bracelates",link:"Bracelates" },
    { image: p5Image, name: "Earings",link:"Earings" },
    
];

const TopCategories = () => {
    const containerRef = useRef(null);
    const timeoutId = useRef(null);
    const intervalId = useRef(null);
    const isMouseOver = useRef(false);
    const isScrolling = useRef(false);
    const isUserScrolling = useRef(false);

    useEffect(() => {
        const startAutoScroll = () => {
            intervalId.current = setInterval(() => {
                if (containerRef.current && !isMouseOver.current && !isUserScrolling.current) {
                    const currentScrollLeft = containerRef.current.scrollLeft;
                    const newScrollLeft = currentScrollLeft + 5; // Adjust scroll amount as needed

                    containerRef.current.scrollLeft = newScrollLeft;
                    if (newScrollLeft >= containerRef.current.scrollWidth) {
                        containerRef.current.scrollLeft = 0;
                    }
                }
            }, 10); // Adjust interval for smoother scrolling
        };

        const stopAutoScroll = () => {
            clearInterval(intervalId.current);
        };

        const handleUserScroll = () => {
            if (containerRef.current && !isMouseOver.current) {
                stopAutoScroll();
                containerRef.current.classList.add("scroll-paused");
                isScrolling.current = true;

                clearTimeout(timeoutId.current);
                timeoutId.current = setTimeout(() => {
                    containerRef.current.classList.remove("scroll-paused");
                    isScrolling.current = false;
                    startAutoScroll();
                }, 1000); // 5 seconds
            }
        };

        const handleScroll = () => {
            if (containerRef.current) {
                const maxScrollLeft =
                    containerRef.current.scrollWidth - containerRef.current.clientWidth;
                const scrollLeft = containerRef.current.scrollLeft;

                // Check if scrolled to the end and adjust scroll behavior
                if (scrollLeft >= maxScrollLeft - 1) {
                    containerRef.current.scrollLeft = 0; // Reset to beginning seamlessly
                }
            }
        };

        const handleMouseEnter = () => {
            isMouseOver.current = true;
            stopAutoScroll();
        };

        const handleMouseLeave = () => {
            isMouseOver.current = false;
            startAutoScroll();
        };

        const handleManualScroll = () => {
            isUserScrolling.current = true;
            stopAutoScroll();
            clearTimeout(timeoutId.current);
            // Optional: Add additional logic or delay before resuming auto scroll
        };

        const handleManualScrollEnd = () => {
            isUserScrolling.current = false;
            startAutoScroll();
        };

        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", handleScroll);
            containerRef.current.addEventListener("mouseenter", handleMouseEnter);
            containerRef.current.addEventListener("mouseleave", handleMouseLeave);
            containerRef.current.addEventListener("scroll", handleUserScroll);
            containerRef.current.addEventListener("wheel", handleManualScroll); // Handle mouse wheel scrolling
            containerRef.current.addEventListener("touchstart", handleManualScroll); // Handle touch scrolling
            containerRef.current.addEventListener("mousedown", handleManualScroll); // Handle mouse click and drag scrolling
            containerRef.current.addEventListener("mouseup", handleManualScrollEnd); // Handle end of manual scrolling
        }

        startAutoScroll(); // Start automated scrolling initially

        // Cleanup function
        const cleanup = () => {
            const currentContainerRef = containerRef.current;
            if (currentContainerRef) {
                currentContainerRef.removeEventListener("scroll", handleScroll);
                currentContainerRef.removeEventListener("mouseenter", handleMouseEnter);
                currentContainerRef.removeEventListener("mouseleave", handleMouseLeave);
                currentContainerRef.removeEventListener("scroll", handleUserScroll);
                currentContainerRef.removeEventListener("wheel", handleManualScroll);
                currentContainerRef.removeEventListener("touchstart", handleManualScroll);
                currentContainerRef.removeEventListener("mousedown", handleManualScroll);
                currentContainerRef.removeEventListener("mouseup", handleManualScrollEnd);
            }
            clearInterval(intervalId.current);
            clearTimeout(timeoutId.current);
        };

        // Cleanup on unmount
        return cleanup;
    }, []);

    const handleScrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // for a smooth scrolling effect
      });
    };

    return (

        <>
            <div className="product-slider container products-container">
                <div>
                    <div className="mt-5 future">
                        <div className="header-container">
                            <h1 className="future-head">Top Categories</h1>
                            <button className="button tsp-button">View More</button>
                        </div>
                        <div className="underline"></div>
                    </div>
                </div>

                <div className="horizontal-scroll-container" ref={containerRef}>
                    <div className="product-slide product-card-wrapper">
                        {/* Double the products array to achieve seamless infinite scrolling */}
                        {products.concat(products).map((product, index) => (
                            <div key={index} className="product">
                              <Link to={product.link}><img
                                    src={product.image}
                                    alt={`Product ${index + 1}`}
                                    className="product-image"
                                    onClick={handleScrollToTop}
                                /></Link> 
                                <h5 className="product-name">{product.name}</h5>
                            </div>
                        ))}
                        {/* Duplicate products enough to cover the width */}
                        {products.concat(products).map((product, index) => (
                            <div key={index + products.length} className="product">
                                <img
                                    src={product.image}
                                    alt={`Product ${index + 1}`}
                                    className="product-image"
                                />
                                <h5 className="product-name" style={{fontFamily:'Baskerville'}}>{product.name}</h5>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Parallax image */}
            <div className="parallax-container">
                <div className="parallax-image">
                    <div className="cms-banner-title-three container">
                        <h3 style={{ color: "#DEAC67"}}>Flat 40% Discount</h3>
                        <h1 style={{fontFamily:'Baskerville'}}>Gold And Diamond Earrings</h1>
                        <p style={{ fontSize: "15px" }}>
                            Designer Diamond Jewellery Necklaces , <br></br> Bracelets , Earrings 
                        </p>
                        <button className="button tsp-button-par " style={{backgroundColor:"#DEAC67"}}><h6>View More</h6></button>
                    </div>
                </div>
            </div>

            {/* Using shop by gender component */}
            <div className="divider-section">
            <h2 className="divider-heading" style={{fontFamily:'Baskerville'}}>BESTSELLERS</h2>
            <p className='text-below-head'>Elegant Rings in Gold , Diamond , Silver and Stones</p>
            <div className="divider-container">
                <img
                    src="divider1.svg"
                    alt="Divider"
                    className="responsive-divider"
                />
            </div>
        </div>
        <div className="mt-5 future background container">
      <div className="row second-row background">
        {cardData.map((item, index) => (
          <div className="col-6 col-md-3 second-four-cards" key={index}>
            <Card title={item.title} image={item.image} link={item.link} />
          </div>
        ))}
      </div>
    </div>
    
        </>
    );
};

export default TopCategories;
