import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductSlider.css";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useFirebase } from "../firebase"; // Adjust import
import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";

const ProductSlider1 = () => {

  const { db } = useFirebase(); // Get Firestore instance
  const [products, setProducts] = useState([]);
  // const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      const q = query(collection(db, "products"), where("subCategory", "==", "First Coursel"));
      const querySnapshot = await getDocs(q);
      const productsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log("products array = ", productsArray)
      setProducts(productsArray);
    };

    fetchProducts();
  }, [db]);

  // const handleProductClick = (product) => {
  //   navigate(`/product/${product.id}`, { state: { product } }); // Navigate to product detail page
  // };

  // const products = [
  //   { id: 1, name: "Ring1", img: "test1.jpg" },
  //   { id: 2, name: "Ring2", img: "test1.jpg" },
  //   { id: 3, name: "Ring3", img: "test1.jpg" },
  //   { id: 4, name: "Ring4", img: "test1.jpg" },
  //   { id: 5, name: "Ring5", img: "test1.jpg" },
  //   { id: 6, name: "Ring6", img: "test1.jpg" },
  //   { id: 7, name: "Ring7", img: "test1.jpg" },
  //   { id: 8, name: "Ring8", img: "test1.jpg" },
  // ];

  // Ensure enough items for infinite scrolling
  const minSlidesToShow = 9; // Max number of slides shown at once (desktop)
  const extendedProducts = products.length < minSlidesToShow 
    ? [...products, ...products, ...products] // Triple the list if needed
    : [...products, ...products]; // Duplicate the list

  const settings = {
    infinite: true,
    slidesToShow: 9, // Maximum number of slides on large screens
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500, // Continuous motion
    speed: 1000, // Smooth scrolling
    cssEase: "linear", // Ensures seamless scrolling
    pauseOnHover: true,
    arrows: false, // Hide arrows for a clean look
    responsive: [
      {
        breakpoint: 1024, // Medium screens
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480, // Phones
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="product-slider-container1">
      <Slider {...settings}>
        {extendedProducts.map((product, index) => (
          <div key={index} className="product-item1">
            <img src={product.mainImageUrl} alt={product.title} loading="lazy" />
            <p>{product.title}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductSlider1;
