import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProductCard from "../Product-Card/ProductCard";
import "./products.css";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useFirebase } from "../firebase"; // Adjust import

const Mens = () => {
  const { db } = useFirebase(); // Get Firestore instance
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      const q = query(collection(db, "products"), where("category", "==", "Mens"));
      const querySnapshot = await getDocs(q);
      const productsArray = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsArray);
    };

    fetchProducts();
  }, [db]);

  const handleProductClick = (product) => {
    navigate(`/product/${product.id}`, { state: { product } }); // Navigate to product detail page
  };

  return (
    <>
      <div className="container-fluid top-container" style={{ height: '150px', width: '100%', position: 'relative', backgroundImage: 'url(https://jewelkin-codezeel.myshopify.com/cdn/shop/files/breadcumb_6.jpg?v=1668746632)', backgroundSize: 'cover', backgroundPosition: 'center', padding: '0px' }}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center' }}>
          <h1 className="future-head" style={{ color: "#031026" }}>Mens Collection</h1>
          <div className="underline" style={{ color: "#031026" }}></div>
        </div>
      </div>

      <main className="page catalog-page" style={{ marginTop: "20px" }}>
        <section className="clean-block clean-catalog dark">
          <div className="container">
            <div className="content">
              <div className="row" style={{ padding: '0px' }}>
                <div className="col-md-12" style={{ padding: "10px" }}>
                  <div className="products">
                    <div className="row">
                      {products.map((product) => (
                        <div key={product.id} className="col-6 col-md-4 col-lg-3" style={{ padding: "10px" }}>
                          <ProductCard
                            imageUrl={product.mainImageUrl}
                            heading={product.title}
                            price={product.price}
                            onClick={() => handleProductClick(product)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Mens;
